<nav class="navbar fixed-top navbar-dark bg-dark flex">
  <a class="navbar-brand" href="#">AGV deliciocisimo Dashboard</a>
</nav>
<div class="container main-container">

  <div style='display: flex; justify-content: space-around;'>
    <h1> <span>{{ mensaje ? mensaje.mensaje : '' }}<i class="fas fa-wifi" [style.color]="connected ? '#6BAA75' : '#F52C29'" ></i></span></h1> 

    <div style="display: flex; align-items: center;">

      <input id='switch' type='checkbox'
      #ctrl="ngModel"  [(ngModel)]="toggle" name="toggle" (click)="activar()">

      <label for='switch'>Toggle </label>
      <span >{{ contador }} </span>

      <a name="" id="" class="btn btn-primary" href="#" role="button" (click)="getData()">ACTIVAR MANUAL</a>
      <a name="" id="" class="btn btn-primary" href="#" role="button" (click)="boton()">ACTIVAR MANUAL2</a>

    </div>
  </div>

  <div class="circulos">

    <div class="item text-center">
      <span class="title"> Distancia (cm)</span>
      <div class="circle centrado jcenter circle1">
        <span>{{ mensaje ? mensaje.distancia : 0 }}</span>
      </div>
    </div>

  </div>
  <div class="circulos">

    <div class="item text-center">
      <span class="title"> RPM Izquierda </span>
      <div class="circle centrado jcenter circle3">
        <span>{{ mensaje ? mensaje.RPM_izq : 0 }}</span>
      </div>
    </div>

    <div class="item text-center">
      <span class="title"> RPM Derecha </span>
      <div class="circle centrado jcenter circle4">
        <span>{{ mensaje ? mensaje.RPM_der : 0 }}</span>
      </div>

    </div>

  </div>


</div>  


<router-outlet>



</router-outlet>