import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable, from } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
  title = 'HOME';
  mensaje: any;

  contador = 0;
  intervalo: any;
  toggle = false;

  connected = false;
  httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  link = 'http://www.mocky.io/v2/5ea172973100002d001eeada';

  constructor(private http: HttpClient) {

  }

  ngOnInit(): void {

  }

  boton() {

    this.http.get(this.link, this.httpOptions)
    .subscribe(Response => {
      this.connected = true;

      this.mensaje = Response;
      console.log(Response);


    });

  }

  getData(): Observable<any> {
    return from(
      fetch(
        this.link, // the url you are trying to access
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'GET', // GET, POST, PUT, DELETE    //http://www.mocky.io/v2/5ea172973100002d001eeada
          mode: 'no-cors' // the most important option     http://187.192.130.93:80/b
        }
      ).then((data) => {
        console.log('data = ', data);
    }));
  }

  activar() {
    console.log(this.toggle);

    if (!this.intervalo) {
      console.log('else');

      this.toggle = false;
      this.intervalo = setInterval(() => {
        this.http.get(this.link, this.httpOptions)
        .subscribe(Response => {
          this.connected = true;

          this.mensaje = Response;
          console.log('asdasdasd');

        });
        this.contador++;

        }, 1000);

    } else {
        this.connected = false;

        console.log('if');
        clearInterval( this.intervalo );
        this.toggle = true;
        this.intervalo = false;
    }
  }



}
